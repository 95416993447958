import * as React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import "../assets/css/main.css"

const TermsConditions = () => {
    return (
        <main className="flex flex-col bg-black-1">
            <Navbar />
            <div className="justify-items-start max-w-6xl mx-auto px-4 sm:px-6 py-24">
                <h1 className="privacy-h1">Terms and Conditions</h1>

                <p className="pt-8 text-gray-4 text-sm md:text-base">
                    Last updated: April 15, 2023
                </p>

                <h2 className="privacy-h2 pt-16">
                    Introduction
                </h2>

                <p className="privacy-p pt-8">
                    Welcome to <strong>GetterStack</strong>, an app developed by <strong>9clan</strong>. By using this app, you
                    agree to the following terms and conditions. These terms govern your use of the app, including
                    any updates or new features that may be added from time to time. If you do not agree to these terms,
                    please do not use the app.
                </p>

                <h2 className="privacy-h2 pt-16">
                    User Accounts
                </h2>

                <p className="privacy-p pt-8">
                    To use certain features of the app, you may be required to create an account. You must provide accurate
                    and complete information when creating your account, and you are responsible for keeping your account
                    information up to date. You are also responsible for maintaining the confidentiality of your account login
                    credentials.
                </p>

                <h2 className="privacy-h2 pt-16">
                    User Consent and Personal Data
                </h2>

                <p className="privacy-p pt-8">
                    By using the app, you consent to the collection, use, and storage of your personal data as described in our
                    privacy policy. The types of personal data we collect may include, but are not limited to, your name, email address,
                    phone number, and usage data.
                </p>

                <p className="privacy-p pt-4">
                    We will only collect and use your personal data for the purposes of providing and improving the app, and we will never
                    share your personal data with third parties without your consent, except as required by law or as necessary to provide
                    the app's services.
                </p>

                <h2 className="privacy-h2 pt-16">
                    User Rights Under GDPR
                </h2>

                <p className="privacy-p pt-8">
                    Under the GDPR, you have certain rights with respect to your personal data, including the right to access, correct, and
                    delete your personal data. You also have the right to object to the processing of your personal data and to request that
                    we restrict the processing of your personal data.
                </p>

                <p className="privacy-p pt-4">
                    To exercise any of these rights, please contact us at <strong>info@9clan.com</strong>. We will respond to your request as soon as reasonably
                    possible, and we may ask for additional information to verify your identity before processing your request.
                </p>

                <h2 className="privacy-h2 pt-16">
                    Data Security
                </h2>

                <p className="privacy-p pt-8">
                    We take reasonable measures to protect your personal data from unauthorized access, use, or disclosure. However, no system can
                    guarantee 100% security, and we cannot guarantee the security of your personal data.
                </p>

                <h2 className="privacy-h2 pt-16">
                    Disclaimers and Limitations of Liability
                </h2>

                <p className="privacy-p pt-8">
                    The app is provided "as is" and without warranty of any kind, either express or implied, including but not limited to
                    warranties of merchantability or fitness for a particular purpose. <strong>9clan</strong> does not warrant that the app will be
                    error-free or uninterrupted, nor does it make any representations regarding the use or results of the app in terms of its
                    accuracy, reliability, or suitability for any particular purpose.
                </p>

                <p className="privacy-p pt-4">
                    In no event shall <strong>9clan</strong> be liable for any damages arising out of or in connection with the use or inability to use
                    the app, including but not limited to direct, indirect, incidental, or consequential damages.
                </p>

                <h2 className="privacy-h2 pt-16">
                    Governing Law
                </h2>

                <p className="privacy-p pt-8">
                    These terms and conditions shall be governed by and construed in accordance with the laws of Netherlands, without giving
                    effect to any principles of conflicts of law. Any legal action or proceeding arising out of these terms or the app shall be
                    brought exclusively in the courts of Netherlands.
                </p>

                <h2 className="privacy-h2 pt-16">
                    Modifications
                </h2>

                <p className="privacy-p pt-8">
                    <strong>9clan</strong> reserves the right to modify these terms and conditions at any time, without notice. Your continued use of the
                    app following any such modification constitutes your agreement to the revised terms and conditions.
                </p>

                <h2 className="privacy-h2 pt-16">
                    Termination
                </h2>

                <p className="privacy-p pt-8">
                    <strong>9clan</strong> may terminate your use of the app at any time, without notice, for any reason or no reason. Upon termination,
                    you must immediately cease using the app and delete any downloaded copies of the app.
                </p>

                <h2 className="privacy-h2 pt-16">
                    Entire Agreement
                </h2>

                <p className="privacy-p pt-8">
                    These terms and conditions, together with our privacy policy, constitute the entire agreement between you and <strong>9clan</strong> with
                    respect to the app, and supersede all prior or contemporaneous communications and proposals.
                </p>
            </div>
            <Footer />
        </main>
    )
}

export default TermsConditions