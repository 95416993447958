import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex justify-center py-4 border-t border-gray-200">
          <p className="text-white text-sm mb-4 ">
            &copy; {new Date().getFullYear()} GetterStack. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
