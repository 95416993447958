import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Navbar = () => {
  return (
    <nav className="fixed w-full pt-4 pb-4 bg-black-1">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <Link to="/">
          <StaticImage
            src="../assets/images/logo_small.png"
            placeholder="blurred"
            alt="logo"
          />
        </Link>
      </div>
    </nav>
  )
}

export default Navbar
